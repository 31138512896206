import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    active: true
}
export const toggleSlice = createSlice({
    name: 'toggleSidebar',
    initialState,
    reducers: {
        toggleSwitch: (state) => {
            state.active = !state.active;
            console.log("state",state)
        },
    }
})
export const toggleSwitch = toggleSlice.actions.toggleSwitch;
export default toggleSlice.reducer;