import React, { useEffect } from 'react'
import snapshotRequest from '../../assets/images/snapshot.jpg'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../component/Breadcrumb';
import { MdKeyboardArrowRight } from "react-icons/md";


const Snapshot = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Breadcrumb />
                        {/* Snapshot Content */}
                        <div className='widget'>
                            <div className='card-header'>
                                Snapshot
                            </div>
                            <hr className="hr" />
                            <div className="row">

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className='card' style={{ height: "175px" }}>
                                        <div className="card-body-category">
                                            <img src={snapshotRequest} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Snapshot Request Form</h5>
                                            <div className='text-container'>
                                                <p className="card-text">Submit a snapshot request via the Snapshot Request Form.</p>
                                            </div>
                                            <Link to="/snapshot/snapshot-request" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></Link>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className='card' style={{ height: "175px" }}>
                                        <div className="card-body-category">
                                            <img src={snapshotRequest} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Repository</h5>
                                            <div className='text-container'>
                                                <p className="card-text">Click here to be redirected to the Snapshot SharePoint data repository.</p>
                                            </div>
                                            <Link to="https://collab.lilly.com/sites/LillyNowSnapshotTST/Shared%20Documents/Forms/AllItems.aspx" target="_blank" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className='card' style={{ height: "175px" }}>
                                        <div className="card-body-category">
                                            <img src={snapshotRequest} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">State Change Data</h5>
                                            <div className='text-container'>
                                                <p className="card-text">State Change Data stores status updates on Contentful entries.</p>
                                            </div>

                                            <Link to="/snapshot/state-change-data" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></Link>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Snapshot