import moment from "moment"
import Breadcrumb from "../../../../component/Breadcrumb"
import { useSelector } from "react-redux";

export const TranslationImportRequest = () => {

    const auth = useSelector((state) => state.auth);

    const confirmationMessage = 'You will receive an e-mail confirmation when the import job is complete. If any errors occurred during the import, you will find an Error Report under Reports >> Translation Cycle Data.';

    const currentTime = new Date();

    return (
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Breadcrumb />
                        <div className="widget">
                            <div className="card-header">Import Job Request</div>
                            <hr className="hr" />
                            <div className="px-3 py-2">
                                <div>
                                    Import Job Request, will update entries at Contentful that are <b>In Translation</b> mode and are part of the JSON file uploaded to this Import Job Request. Post the export job is completed, such entries will have <b>In Translation</b> button cleared off, rendering them back to editable mode. It is import that correct Import file is uploaded before running the Import Job.
                                </div>
                                <div className="py-2"><span className="mandatory">*</span>Import file is provided by Language Scientific Team.</div>
                            </div>
                            <div className="container">
                                <div className="row align-items-center my-4">
                                    <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                                        User Name
                                    </div>
                                    <input
                                        className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                                        value={auth.userInfo?.name}
                                        disabled
                                    />
                                </div>
                                <div className="row align-items-center my-4">
                                    <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                                        Date & Time
                                    </div>
                                    <input
                                        className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                                        value={moment(currentTime).format("MM/DD/YYYY hh:mm a")}
                                        disabled
                                    />
                                </div>
                                <div className="row align-items-center my-4">
                                    <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                                        Published (File Upload) <span className="mandatory">*</span>
                                    </div>
                                    <input
                                        className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                                        type="file"
                                    />
                                </div>
                                <div className="row align-items-center my-2">
                                    <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                                        Static (File Upload) <span className="mandatory">*</span>
                                    </div>
                                    <input
                                        className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                                        type="file"
                                    />
                                </div>
                                <div className="pb-2 pt-2">
                                    <div className="row">
                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12" />
                                        <button
                                            className="submitButton col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12"
                                            type="button"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12" />
                                        <span className="mandatory-small">*Mandatory</span>
                                    </div>
                                </div>
                                <div className="text-teal pb-2">
                                    {confirmationMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}