import excel from "exceljs";

export const generateSheet = async ({ headers, data, fileName }) => {
    const workbook = new excel.Workbook();
    const worksheet = workbook.addWorksheet("Export");

    worksheet.columns = headers;

    worksheet.addRows(data);

    worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell, _) => {
            if (rowNumber === 1) {
                cell.font = {
                    bold: true,
                };
            }
        });
        row.commit();
    });

    return await workbook.xlsx.writeBuffer({ fileName });
}