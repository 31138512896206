import { useEffect, useState } from "react";
import Breadcrumb from "../../../../component/Breadcrumb";
import moment from "moment";
import { Link } from "react-router-dom";
import { Pagination } from "../../../../component/Common/pagination";
import { ReportLink } from "./components/reportLink";
import { useSelector } from "react-redux";

export const HistoricalData = () => {
  const ITEM_PER_PAGE = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const [sampleData, setSampleData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const data = [];
    for (let i = 0; i < 110; i++) {
      const randomData = new Date(new Date() - Math.random() * 1e12);
      data.push({
        id: i + 1,
        month: moment(randomData).format("MMMM"),
        year: moment(randomData).format("YYYY"),
        date: moment(randomData).format("DD"),
        time: moment(randomData).format("hh:mm A"),
        author: auth.userInfo?.name,
        status: i % 2 === 0 ? "Success" : "Failure",
        published: i % 2 === 0 ? null : "File",
        static: i % 3 === 0 ? null : "File",
        error: i % 3 === 6 ? null : "File",
      });
    }

    setSampleData(data);

    setTotalPage(Math.ceil(data.length / ITEM_PER_PAGE));
  }, []);

  useEffect(() => {
    const result = sampleData.slice(
      (currentPage - 1) * ITEM_PER_PAGE,
      currentPage * ITEM_PER_PAGE
    );
    setPaginatedData(result);
  }, [currentPage, sampleData]);

  const onPageChange = (index) => {
    setCurrentPage(index);
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Translation Cycle Data</div>
              <hr className="hr" />
              <p className="p-2">
                This is historical list of all export and import jobs run using the ancillary portal. To locate and download files, click on the request ID next to the desired Export or Import date.
              </p>
              <div className="mx-4">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr className="text-black text-red">
                        <th scope="col">Report Id</th>
                        <th scope="col">Month</th>
                        <th scope="col">Year</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Author</th>
                        <th scope="col">Status</th>
                        <th scope="col">Published File</th>
                        <th scope="col">Static File</th>
                        <th scope="col">Error File</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>
                              <Link
                                className="report-link"
                                to={`/translations/reports/historical-data/${data.id}`}
                              >
                                Report-{data.id}
                              </Link>
                            </td>
                            <td>{data.month}</td>
                            <td>{data.year}</td>
                            <td>{data.date}</td>
                            <td>{data.time}</td>
                            <td>{data.author}</td>
                            <td>{data.status}</td>
                            <td>
                              <ReportLink to={data.published} />
                            </td>
                            <td>
                              <ReportLink to={data.static} />
                            </td>
                            <td>
                              <ReportLink to={data.error} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  totalPage={totalPage}
                  itemPerPage={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
