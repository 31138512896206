import React, {useEffect, useState, useMemo, useCallback } from "react";
import moment from "moment";
import Breadcrumb from "../../../../component/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { contextRequestForm } from "../../../../redux/feature/translation/context/contextRequestFormSlice";
import errorToast from "../../../../component/utils/errorToast";
import Loader from "../../../../component/Common/Loader";

export const TranslationContextFile = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const currentTime = useMemo(() => new Date(), []);
  const { name: userName, username: userEmail } = useMemo(
    () => auth.userInfo,
    [auth.userInfo]
  );
  const confirmationMessage = useMemo(
    () =>
      "You will receive an e-mail confirmation when the context file job is complete. Locate and download the Context file under Reports.",
    []
  );
  const errorMessage = useMemo(
    () => "There is an error in the context file.",
    []
  );

  const [time, setTime] = useState(currentTime);
  const [author, setAuthor] = useState(userName || "");
  const [type, setType] = useState("Context File");
  const [email, setEmail] = useState(userEmail || "");
  const responseContextFile = useSelector((state) => state.contextRequestForm);
  useEffect(() => {
    if (responseContextFile.isSuccess) {
      console.log("Context file request successful ==>");
    } else if (responseContextFile.isError) {
      errorToast("Context file request failed");
    }
  }, [responseContextFile.isSuccess]);

  const handleSubmit = useCallback(() => {
    if (!time) {
      errorToast("Time is a Mandatory field");
    } else if (!author) {
      errorToast("Author is a Mandatory field");
    } else if (!type) {
      errorToast("Type is a Mandatory field");
    } else if (!email) {
      errorToast("Email is a Mandatory field");
    } else {
      const context_file_request = {
        author: author,
        type: type,
        email_id: email,
        timestamp: time.toISOString(),
      };
      dispatch(contextRequestForm(context_file_request));
    }
  }, [author, email, time, type, dispatch]);

  const renderContent = () => {
    if (responseContextFile.loading) {
      return <Loader />;
    } else if (responseContextFile.isSuccess) {
      return (
        <div className="pb-2 text-teal">
          <p>
            Request ID :{" "}
            {responseContextFile.data.length > 0
              ? responseContextFile.data[responseContextFile.data.length - 1]
                  .requestId
              : ""}
          </p>
          <p>{confirmationMessage}</p>
        </div>
      );
    } else if (responseContextFile.isError) {
      return <div className="pb-2 text-teal">{errorMessage}</div>;
    } else {
      return <div className="pb-2 text-teal">{confirmationMessage}</div>;
    }
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Context File</div>
              <hr className="hr" />
              <div className="px-3 py-2">
                The Context file is current snapshot of the LillyNow page
                structure and shows the relationship between pages and entries;
                it is provided to the translation vendor along with the export
                files.
              </div>
              <div className="container">
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    User Name
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={userName}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-2">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    Date & Time
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={moment(currentTime).format("MM/DD/YYYY hh:mm a")}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-4">
                  <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12" />
                  <button
                    onClick={handleSubmit}
                    className="submitButton col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12"
                    type="button"
                  >
                    Submit
                  </button>
                </div>
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
