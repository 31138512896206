import React, { useRef, useState } from 'react';
import './model.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ErrorImg from '../assets/images/error.png'

const style = {
    submitButton: { color: "#74AA6A", border: "none", borderRadius: "5px", backgroundColor: "#CD3B2C", color: "#fff", boxShadow: "none" }
}

const Error = ({ isOpen, response }) => {
    const [open, setOpen] = useState(isOpen);

    const myRef = useRef(null);
    const handleSubmit = () => {
        setOpen(false);
        window.location.reload();
    }
    return (
        <>
            <div ref={myRef} />

            <Modal
                open={open}
                onClose={handleSubmit}
                center
                container={myRef.current}
            >
                <div style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <img src={ErrorImg} width={50} height={50} className='icon-model' />
                    <h6 style={{ fontSize: "14px", marginTop: "20px", paddingTop: "10px", color: "#CD3B2C" }}>
                        {response.body}
                    </h6>
                    <p style={{ fontSize: "12px", color: "#000000", fontWeight:"bold" }}>Error Status Code : {response.statusCode}</p>
                    <button onClick={handleSubmit} style={style.submitButton}>Try Again </button>
                </div>
            </Modal>
        </>
    )
}

export default Error