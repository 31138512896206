import React, { useState } from 'react';
import './model.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { AiFillCheckCircle } from "react-icons/ai";
import SuccessImg from '../assets/images/success.png'

const style = {
    submitButton: { color: "#74AA6A", border: "none", borderRadius: "5px", backgroundColor: "#74AA6A", color: "#fff", boxShadow: "none" }
}

const Success = ({ isOpen, message }) => {
    const [open, setOpen] = React.useState(isOpen);
    const myRef = React.useRef(null);

    const handleSubmit = () => {
        setOpen(false);
        window.location.reload();
    }
    return (
        <>
            <div ref={myRef} />

            <Modal
                open={open}
                onClose={handleSubmit}
                center
                container={myRef.current}
            >
                <div style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {/* <AiFillCheckCircle size={50} color='#74AA6A' className='icon-model'/> */}
                    <img src={SuccessImg} width={50} height={50} className='icon-model' />
                    <h6 style={{ fontSize: "18px", marginTop: "5px", color: "#74AA6A", paddingTop: "20px" }}>
                        Submitted Successfully
                    </h6>
                    <p style={{ fontSize: "14px", fontWeight: 'semi-bold', color: "#000000" }}>{message}</p>
                    <button onClick={handleSubmit} style={style.submitButton}> OK </button>
                </div>
            </Modal>
        </>
    )
}

export default Success