import { configureStore } from '@reduxjs/toolkit'
import authReducer from './feature/auth/authSlice';
import snapshotRequest from './feature/snapshot/requestSlice';
import adGroupToken from './feature/auth/adGroupSlice';
import userRole from './feature/auth/roleSlice';
import stateChangeRequest from './feature/snapshot/stateChangeSlice'
import loadingSlice from './feature/loading/loadingSlice';
import toggleSlice from './feature/common/toggleSlice';
import contextRequestForm from './feature/translation/context/contextRequestFormSlice';
import ReportData from './feature/translation/report/ReportDataSlice';
import exportReportRequestForm from './feature/translation/export/exportReportFormSlice';
import exportUpdateRequestForm from './feature/translation/export/exportUpdateFormSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    snapshotRequest: snapshotRequest,
    stateChangeRequest: stateChangeRequest,
    loadingSlice:loadingSlice,
    adGroupToken:adGroupToken,
    userRole:userRole,
    toggleSlice:toggleSlice,
    contextRequestForm: contextRequestForm,
    ReportData: ReportData,
    exportReportRequestForm: exportReportRequestForm,
    exportUpdateRequestForm: exportUpdateRequestForm
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})
export default store;