import React from 'react';
import FooterImg from '../../assets/images/company.png'

const Footer = () => {
    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-12">
                        While Lilly has made every effort to make the information here as accurate as possible, Lilly’s compensation and benefit programs and other services are detailed in separate official documents. In the event of any inconsistencies between this information and those documents, the terms of those documents will control. The company reserves the right to amend, modify, suspend or terminate its programs and services at any time to the extent permitted by applicable law.
                    </div>

                </div>
                <div className="copyright-text">
                    <div className="col-sm-12 col-12">
                        Copyright © {new Date().getFullYear()} Eli Lilly and Company and its affiliates. All rights reserved.
                    </div>
                    <img src={FooterImg} width={80} height={60} />
                </div>
            </div>
        </div>
    )
}

export default Footer;