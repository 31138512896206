import React, { useState, useEffect } from 'react'
import snapsghotImg from '../../assets/images/snapshot.jpg'
import translationImg from '../../assets/images/translation.png'
import refreshTargetsImg from '../../assets/images/refresh_targets.png'
import dataPullImg from '../../assets/images/data_pull.png'
import testingImg from '../../assets/images/testing.png'
import reportImg from '../../assets/images/reports.png'
import syncImg from '../../assets/images/sync.png'
import Breadcrumb from '../../component/Breadcrumb';
import { MdKeyboardArrowRight } from "react-icons/md";

import './Home.css';
import { useSelector } from 'react-redux';
import _isContains from '../../component/utils/compareRole';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';


const Home = ({ isSnapshotAccess }) => {
    const auth = useSelector((state) => state.auth);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content ">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Breadcrumb />
                        <motion.div className='username-text' initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}>
                            Welcome {auth.userInfo?.name}
                            <div className='horizontal-line'></div>
                        </motion.div>
                        <div className='widget'>
                            <div className='card-header'>
                                Quick Links
                            </div>
                            <div className="row">

                                <div className='card-container'>

                                    <div className='card-quick-links'>

                                        <div className="card-body" onClick={() => openInNewTab(' https://now.lilly.com')}>
                                            <h5 className="text-muted">Lilly Now</h5>
                                            <div>
                                                https://now.lilly.com
                                            </div>
                                        </div>

                                    </div>
                                    <div className='card-quick-links'>

                                        <div className="card-body" onClick={() => openInNewTab('https://be.contentful.com/login')}>
                                            <h5 className="text-muted">Contentful</h5>
                                            <div>
                                                https://be.contentful.com/login
                                            </div>
                                        </div>

                                    </div>
                                    <div className='card-quick-links'>

                                        <div className="card-body" onClick={() => openInNewTab('https://lilly-jira.atlassian.net/jira/your-work')}>
                                            <h5 className="text-muted">Jira</h5>
                                            <div>
                                                https://lilly-jira.atlassian.net/jira/your-work
                                            </div>
                                        </div>

                                    </div>
                                    <div className='card-quick-links'>

                                        <div className="card-body" onClick={() => openInNewTab('https://lilly-confluence.atlassian.net')}>
                                            <h5 className="text-muted">Confluence</h5>
                                            <div>
                                                https://lilly-confluence.atlassian.net
                                            </div>
                                        </div>

                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className='widget'>
                            <div className='card-header'>
                                Category Cards
                            </div>
                            <hr className="hr" />
                            <div className="row">

                                {isSnapshotAccess && <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={snapsghotImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Snapshot</h5>
                                            <p className="card-text">A snapshot is our versioning of a portal content. We must be able to produce a snapshot for legal at any point in time in whatever language due to litigation purpose.</p>
                                            <Link to="/snapshot" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></Link>
                                        </div>
                                    </div>

                                </div>}
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={translationImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Translations</h5>
                                            <p className="card-text">The translation process is managed centrally by the LillyNow team and the actual translation of the global content is provided by our translation vendor in 7 core languages.</p>
                                            <a href="/translations" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={refreshTargetsImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Refresh Targets</h5>
                                            <p className="card-text">Content for this card is coming soon</p>
                                            <a href="#" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={dataPullImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Data Pull</h5>
                                            <p className="card-text">Content for this card is coming soon</p>
                                            <a href="#" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={reportImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">International APIs / Reports</h5>
                                            <p className="card-text">Content for this card is coming soon</p>
                                            <a href="#" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={testingImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Testing Automation</h5>
                                            <p className="card-text">Content for this card is coming soon</p>
                                            <a href="#" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">

                                    <div className='card'>

                                        <div className="card-body-category">
                                            <img src={syncImg} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Contentful Synchronization</h5>
                                            <p className="card-text">Content for this card is coming soon</p>
                                            <a href="#" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home