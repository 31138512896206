import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    permission: {},
    isSuccess: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authAdded: (state, action) => {
            state.userInfo = action.payload;
            state.isSuccess = true
        }
    },
    extraReducers: {},
})

export const { authAdded } = authSlice.actions

export default authSlice.reducer