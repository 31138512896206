import React from "react";

function _isContains(JSON, value) {
    console.log("VALUE inside isContains",value);
    console.log("JSON inside isContains",JSON);
    console.log("JSON length",JSON.length);
    var hasMatch = false;
    for (var index = 0; index < JSON.length; ++index) {
        var role = JSON[index];
        console.log("role",role);
        console.log("role.displayName",role.displayName);
        if (role.displayName == value) {
            hasMatch = true;
            break;
        }
    }
    console.log("HasMatch",hasMatch);
    return hasMatch;
}

export default _isContains
