import { useEffect, useState } from "react"

function range(start, stop, step) {
    if (typeof stop == 'undefined') {
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
};

export const Pagination = ({ currentPage, onPageChange, totalPage, itemPerPage }) => {

    const [currentStart, setCurrentStart] = useState(1);

    const [currentEnd, setCurrentEnd] = useState(null);

    const goPrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    }

    const goForward = () => {
        if (currentPage < totalPage) {
            onPageChange(currentPage + 1);
        }
    }

    useEffect(() => {
        setCurrentEnd(totalPage >= itemPerPage ? itemPerPage : totalPage);
    }, [totalPage, itemPerPage])

    useEffect(() => {

        if (currentEnd) {
            if (currentPage > currentEnd) {
                setCurrentStart(currentStart + itemPerPage);
                if (currentEnd + itemPerPage < totalPage) {
                    setCurrentEnd(currentEnd + itemPerPage);
                } else {
                    setCurrentEnd(totalPage)
                }
            }

            if (currentPage < currentStart) {
                setCurrentStart(currentStart - itemPerPage);
                if (currentEnd - itemPerPage > totalPage) {
                    setCurrentEnd(currentEnd - itemPerPage);
                } else {
                    setCurrentEnd(currentPage)
                }
            }
        }

    }, [currentPage, totalPage, currentEnd, currentStart, itemPerPage])

    return <div className="d-flex justify-content-center py-1">
        <nav aria-label="navigation">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={goPrevious}>
                    <div className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </div>
                </li>
                {
                    1 < currentStart ? <li className='page-item'>
                        <div className="page-link">&hellip;</div>
                    </li> : null
                }
                {
                    range(currentStart, currentEnd + 1, 1).map(index => {
                        return <li key={index} className={`page-item ${currentPage === index ? 'active' : ''}`} onClick={() => onPageChange(index)}>
                            <div className="page-link">{index}</div>
                        </li>
                    })
                }
                {
                    totalPage > currentEnd ? <li className='page-item'>
                        <div className="page-link">&hellip;</div>
                    </li> : null
                }
                <li className={`page-item ${currentPage === totalPage ? 'disabled' : ''}`} onClick={goForward}>
                    <div className="page-link" aria-label="Next" onClick={goForward}>
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
}