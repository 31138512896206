import { Link } from "react-router-dom";
import { MdDownload } from "react-icons/md";

export const ReportLink = ({ to, title }) => {
  if (to) {
    return (
      <Link to={to} className="report-link">
        <MdDownload size={20} />
      </Link>
    );
  }

  return <span>NA</span>;
};
