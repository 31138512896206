import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../component/Breadcrumb";
import moment from "moment";
import { Link } from "react-router-dom";
import { Pagination } from "../../../../component/Common/pagination";
import { MdDownload } from "react-icons/md";
import saveAs from "save-file";
import { generateSheet } from "./Utils/generateSheet";
import { SortedIcon } from "./Components/SortedIcons";
import { useDispatch, useSelector } from "react-redux";
import { ReportData } from "../../../../redux/feature/translation/report/ReportDataSlice";
import Loader from "../../../../component/Common/Loader";
import errorToast from "../../../../component/utils/errorToast";

export const FullExportData = () => {
  const ITEM_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sampleData, setSampleData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [order, setOrder] = useState("asc");
  const responseFullExport = useSelector((state) => state.ReportData);
  const dispatch = useDispatch();
  const hasRequestId = sampleData.length > 0 && sampleData.some(data => data.requestId);

  useEffect(() => {
    const request = {
      type: "fullExportUpdate",
      download: false,
    };
    dispatch(ReportData(request));
  }, [dispatch]);

  useEffect(() => {
    // Accessing value inside data[0]
    const data =
      responseFullExport.data?.[0]?.value?.map((obj) => ({
        requestId: obj.request_id,
        timestamp: obj.timestamp,
        author: obj.author,
        email: obj.email_id,
      })) || [];
  
    setSampleData(data);
    setTotalPage(Math.ceil(data.length / ITEM_PER_PAGE));
  }, [responseFullExport.data]);
  

  useEffect(() => {
    const sortedData = [...paginatedData].sort((a, b) => {
      const factor = order === "asc" ? 1 : -1;
      if (sortBy === "requestId") {
        return (a[sortBy] - b[sortBy]) * factor;
      }
      if (sortBy === "timestamp") {
        return moment.utc(a[sortBy]).diff(moment.utc(b[sortBy])) * factor;
      }
      return (
        a[sortBy]?.toString().localeCompare(b[sortBy]?.toString()) * factor
      );
    });
    setPaginatedData(sortedData);
  }, [sortBy, order, paginatedData]);

  useEffect(() => {
    const start = (currentPage - 1) * ITEM_PER_PAGE;
    const end = start + ITEM_PER_PAGE;
    setPaginatedData(sampleData.slice(start, end));
  }, [currentPage, sampleData]);

  const onPageChange = (index) => {
    setCurrentPage(index);
    setOrder("asc");
    setSortBy(null);
  };

  const handleSort = (label) => {
    if (sortBy === label) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortBy(label);
      setOrder("asc");
    }
  };

  const exportFile = async () => {
    const headers = [
      { header: "Request Id", key: "requestId", width: 15 },
      { header: "Author", key: "author", width: 15 },
      { header: "Email", key: "email", width: 10 },
      { header: "Timestamp", key: "timestamp", width: 25 },
    ];
    const fileName = "Full Export Update";
    const fileBuffer = await generateSheet({
      headers,
      data: sampleData,
      fileName,
    });
    saveAs(fileBuffer, fileName + ".xlsx");
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Full Export Update</div>
              <hr className="hr" />
              <p className="p-2">
                List of all the Full Export Updates that were created, as a report
                request was submitted through use Translation Request Form -
                Full Export Update button. A Full Export Update when downloaded is in JSON
                format and provides information on entries that are not updated by "Integration User" and are not in "Never Translate"
                and are in Published status with below mentioned details
              </p>
              <div className="mx-4">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn exportButton col-xl-1 col-lg-4 col-md-6 col-sm-12 col-12"
                    onClick={exportFile}
                    tabIndex="0"
                  >
                    <MdDownload size={18} />
                    Export
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr className="text-black text-red">
                        <th scope="col">
                          <div
                            className="sort-icon d-flex align-items-center gap-2 justify-content-center"
                            data-active={sortBy === "requestId"}
                            onClick={() => handleSort("requestId")}
                          >
                            <span>Request Id</span>
                            <SortedIcon
                              label="requestId"
                              order={order}
                              sortBy={sortBy}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div
                            className="sort-icon d-flex align-items-center gap-2 justify-content-center"
                            data-active={sortBy === "author"}
                            onClick={() => handleSort("author")}
                          >
                            <span>Author</span>
                            <SortedIcon
                              label="author"
                              order={order}
                              sortBy={sortBy}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div
                            className="sort-icon d-flex align-items-center gap-2 justify-content-center"
                            data-active={sortBy === "email"}
                            onClick={() => handleSort("email")}
                          >
                            <span>Email</span>
                            <SortedIcon
                              label="email"
                              order={order}
                              sortBy={sortBy}
                            />
                          </div>
                        </th>
                        <th scope="col">
                          <div
                            className="sort-icon d-flex align-items-center gap-2 justify-content-center"
                            data-active={sortBy === "timestamp"}
                            onClick={() => handleSort("timestamp")}
                          >
                            <span>Timestamp</span>
                            <SortedIcon
                              label="timestamp"
                              order={order}
                              sortBy={sortBy}
                            />
                          </div>
                        </th>
                        <th scope="col">Non translated data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(responseFullExport.loading || !hasRequestId)? (
                        <Loader />
                      ) : (
                        paginatedData.map((data) => (
                          <tr key={data.requestId}>
                            <td>
                              <Link
                                className="report-link"
                                to={`/translations/reports/full-export/full-export-translated-data/${data.requestId}`}
                              >
                                {"Request-" + data.requestId}
                              </Link>
                            </td>
                            <td>{data.author}</td>
                            <td>{data.email}</td>
                            <td>
                              {moment(data.timestamp).format(
                                "DD-MMM-YYYY hh:mm A"
                              )}
                            </td>
                            <td>
                              <Link
                                className="report-link"
                                to={`/translations/reports/full-export/full-export-not-translated-data/${data.requestId}`}
                              >
                                {"Non Translated data Request -" + data.requestId}
                              </Link>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  totalPage={totalPage}
                  itemPerPage={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
