import React from 'react'
import Brand from '../../assets/images/brand.png';
import userimg from '../../assets/images/user_icon.png';
import { BsSearch } from 'react-icons/bs';
import msalInstance from '../../authConfig';
import { Link } from 'react-router-dom';

const style = {
    searchIcon: {
        position: "absolute",
        marginLeft: "150px",
        height: "10px",
        top: "25px",
        display: "flex",
        alignIten: "center"
    }
}
// const msalInstance = new PublicClientApplication(msalConfig);
console.log("msalInstance Header jsx ::", msalInstance);

const Header = () => {
    return (
        <div className="dashboard-header">
            <nav className="navbar navbar-expand-lg fixed-top">
                <Link className="navbar-brand" to="/">
                    <div className='row align-items-center'>
                        <img src={Brand} width="120" height="40" />
                        <div className='app-logo'>Ancillary Portal</div>
                    </div>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto navbar-right-top">
                        <li className="nav-item">
                            <div id="custom-search" className="top-search-bar">
                                <input className="form-control" type="text" placeholder="Search" />
                                <span style={style.searchIcon}>
                                    <BsSearch color='#000000' />
                                </span>
                            </div>
                        </li>

                        <li className="nav-item dropdown nav-user">
                            <a className="nav-link nav-user-img" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={userimg} alt="" className="user-avatar-md rounded-circle" /><span style={{ color: "#fff", paddingLeft: "2px" }}>Logout</span></a>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header