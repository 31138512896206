import React, { useRef, useState } from 'react';
import './model.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ErrorImg from '../assets/images/error.png'

const style = {
    submitButton: { color: "#74AA6A", border: "none", borderRadius: "5px",cursor:"pointer" ,backgroundColor: "#CD3B2C", color: "#fff", boxShadow: "none" }
}

const LoggedOut = ({ isOpen }) => {

    sessionStorage.clear();
    const [open, setOpen] = useState(isOpen);

    const myRef = useRef(null);
    const handleSubmit = () => {
        setOpen(false);
        window.location.reload();
    }
    return (
        <>
            <div ref={myRef} />

            <Modal
                open={open}
                onClose={handleSubmit}
                center
                container={myRef.current}
            >
                <div style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <img src={ErrorImg} width={50} height={50} className='icon-model' />
                    <h6 style={{ fontSize: "14px", marginTop: "20px", paddingTop: "10px", color: "#CD3B2C" }}>
                       Session Expired
                    </h6>
                    <p style={{ fontSize: "12px", color: "#000000", fontWeight:"bold" }}>Please login again</p>
                    <button onClick={handleSubmit} style={style.submitButton}>Login </button>
                </div>
            </Modal>
        </>
    )
}

export default LoggedOut