import React, { useState } from "react";

import useTable from "../../hooks/useTable";
import useSortableData from '../../hooks/useSortableData '
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import moment from "moment";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Link } from "react-router-dom";

const Table = ({ data, rowsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { slice, range } = useTable(data, currentPage, rowsPerPage);

  const { items, requestSort, sortConfig } = useSortableData(slice);


  return (
    <>
      <table className={styles.table} id="table-to-xls">
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>Entry ID</th>
            <th className={styles.tableHeader}>Previous State</th>
            <th className={styles.tableHeader}>Current State</th>
            <th className={styles.tableHeader}>Created Date</th>
            <th className={styles.tableHeader}>Changed Date</th>
          </tr>
        </thead>
        <tbody>
          {items.map((el) => (
            <tr className={styles.tableRowItems} key={el.id}>
              <td className={styles.tableCell}>{el.Entry_Id}</td>
              <td className={styles.tableCell}>{el.Previous_State.toUpperCase()}</td>
              <td className={styles.tableCell}>{el.Current_State.toUpperCase()}</td>
              <td className={styles.tableCell}>{moment(el.Created_Date).utc().format('YYYY-MM-DD')}</td>
              <td className={styles.tableCell}>{moment(el.Changed_Date).utc().format('YYYY-MM-DD')}</td>

            </tr>
          ))}
        </tbody>
      </table>
      <hr className="hrBottom" />


      <div className="excel-export">
        <div className="action-button">
          <Link to="/snapshot/state-change-data" onClick={() => window.location.reload()} className="btn btn-outline-primary">Cancel</Link>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-export"
            table="table-to-xls"
            filename="state-change"
            sheet="state-change"
            buttonText="Export" />

        </div>
      </div>


      <TableFooter range={range} slice={items} setCurrentPage={setCurrentPage} currentPage={currentPage} />
    </>
  );
};

export default Table;