import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/stateChangeData.css";
import Breadcrumb from '../../component/Breadcrumb';
import Table from '../../component/Table';
import errorToast from '../../component/utils/errorToast';
import { stateRequest } from '../../redux/feature/snapshot/stateChangeSlice';
import { useDispatch, useSelector } from 'react-redux';
import SomethingWrong from '../../model/somethingWrong';
import { states } from '../../DataPull'
import Loader from '../../component/Common/Loader';
import Error from '../../model/error';
import { convert } from '../../helper/helper';
import convertDate from '../../component/utils/helper';
import { Link } from 'react-router-dom';

const StateChangeData = () => {

    const originalDAte = new Date();
    const [startDate, setStartDate] = useState(originalDAte);
    const [endDate, setEndDate] = useState(originalDAte);
    const callRef = useRef();
    const callRefEnd = useRef();
    const dispatch = useDispatch();
    const changeData = useSelector(state => state.stateChangeRequest);
    console.log("error", changeData)
    const [stateChangeData, setStateChangeData] = useState(changeData);
    const [entryId, setEntryId] = useState();
    const [submitBtn, setSubmitBtn] = useState(false);

    useEffect(() => {

        setStateChangeData(changeData.data);

    }, [changeData])

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            className='form-control'
            placeholder="YYYY-MM-DD"
        ></input>
    ));

    const DateCustomInputEnd = forwardRef(({ value, onClick, onChange }, ref) => (

        <input
            value={value}
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            className='form-control'
            placeholder="YYYY-MM-DD"
        ></input>
    ));


    const handleEntryId = (e) => {
        setEntryId((prevState) => (e.target.value));
        if (e.target.value.trim().length < 1) {
            setSubmitBtn(false)
        } else {
            setSubmitBtn(true)
        }
    }

    const handleSubmit = () => {
        if (entryId == null && entryId == undefined) {
            errorToast("Mandatory fields must be filled in")
        }
        else if (startDate == null && startDate == undefined) {
            errorToast("Mandatory fields must be filled in")
        }
        else if (endDate == null && endDate == undefined) {
            errorToast("Mandatory fields must be filled in")
        } else {
            const stateChangeRequest = {
                "entry_id": entryId,
                "start_date": convertDate(startDate),
                "end_date": convertDate(endDate)
            };
            console.log(stateChangeRequest)
            dispatch(stateRequest(stateChangeRequest));
        }
    }

    return (
        <div className="dashboard-ecommerce">

            {
                changeData.loading ? <Loader /> :
                    <div className="container-fluid dashboard-content ">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Breadcrumb />

                                {
                                    changeData.error && <Error isOpen={true} response={changeData.data} />
                                }

                                <div className='widget'>
                                    <div className='card-header'>
                                        State Change Data
                                    </div>
                                    <hr className="hr" />

                                    {
                                        !stateChangeData.length > 0 &&
                                        <>
                                            <form>
                                                <div className='state-change-data-form'>
                                                    <div className='form-size'>
                                                        <div className="form-group">
                                                            <h6>Contentful Entry ID<span className="mandatory">*</span></h6>
                                                            <input type='text' className='form-control' onChange={handleEntryId} placeholder='Please enter Contentful id' />
                                                        </div>
                                                    </div>

                                                    <div className='form-group'>
                                                        <h6>Start Date<span className="mandatory">*</span></h6>
                                                        <DatePicker
                                                            ref={callRef}
                                                            selected={startDate}
                                                            shouldCloseOnSelect={false}
                                                            peekNextMonth
                                                            customInput={<DateCustomInput />}
                                                            showMonthDropdown
                                                            onChange={(date) => setStartDate(date)}
                                                            showYearDropdown
                                                            maxDate={new Date()}
                                                            autocomplete={false}
                                                            adjustDateOnChange
                                                            dropdownMode="select"
                                                            yearDropdownItemNumber={20}
                                                            scrollableMonthYearDropdown={true}
                                                            dateFormat="yyyy-MM-dd"

                                                        >
                                                            <div className='button-footer'>
                                                                <button onClick={() => {
                                                                    setStartDate(originalDAte)
                                                                    callRef.current.setOpen(false)
                                                                }} className='btn-cancel-datepicker'>Cancel</button>
                                                                <button onClick={() => {
                                                                    callRef.current.setOpen(false)
                                                                }} className='btn-ok-datepicker'>ok</button>
                                                            </div>
                                                        </DatePicker>
                                                    </div>

                                                    {/* End Date */}
                                                    <div className='form-group'>
                                                        <h6>End Date<span className="mandatory">*</span></h6>
                                                        <DatePicker
                                                            ref={callRefEnd}
                                                            selected={endDate}
                                                            shouldCloseOnSelect={false}
                                                            peekNextMonth
                                                            customInput={<DateCustomInput />}
                                                            showMonthDropdown
                                                            onChange={(date) => setEndDate(date)}
                                                            showYearDropdown
                                                            maxDate={new Date()}
                                                            autocomplete={false}
                                                            adjustDateOnChange
                                                            dropdownMode="select"
                                                            yearDropdownItemNumber={20}
                                                            scrollableMonthYearDropdown={true}
                                                            dateFormat="yyyy-MM-dd"

                                                        >
                                                            <div className='button-footer'>
                                                                <button onClick={() => {
                                                                    setEndDate(originalDAte)
                                                                    callRefEnd.current.setOpen(false)
                                                                }} className='btn-cancel-datepicker'>Cancel</button>
                                                                <button onClick={() => {
                                                                    callRefEnd.current.setOpen(false)
                                                                }} className='btn-ok-datepicker'>ok</button>
                                                            </div>
                                                        </DatePicker>
                                                    </div>
                                                </div>
                                            </form>

                                            <hr className="hrBottom" />


                                            <div className="action-button">
                                                <Link to="/snapshot/state-change-data" onClick={() => window.location.reload()} className="btn btn-outline-primary">Cancel</Link>
                                                <button type='submit' className={`${submitBtn == true ? "btn btn-export" : "btn btn-primary"}`} onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </>
                                    }

                                    {stateChangeData.length > 0 && !changeData.error &&
                                        <Table data={stateChangeData} rowsPerPage={8} />
                                    }
                                    {/* <Table data={states} rowsPerPage={8} /> */}
                                </div>

                            </div>


                        </div>


                    </div>
            }
        </div >
    )
}

export default StateChangeData