import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from "use-react-router-breadcrumbs";

const Breadcrumb = () => {

    const breadcrumbs = useBreadcrumbs();

    return (
        <div className="page-header">

            <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">

                        {breadcrumbs.map((ele, ind) => {
                            const notLast = ind < breadcrumbs.length - 1;
                            const routeName = ele.breadcrumb;

                            if (notLast) {
                                return (
                                    <li className="breadcrumb-item" key={ind}>
                                        <Link to={ele.key} className="breadcrumb-link">{ele.breadcrumb}</Link>
                                    </li>
                                );
                            } else {
                                return (
                                    <li className="breadcrumb-item active" key={ind}>
                                        {ele.breadcrumb}
                                    </li>
                                )
                            }
                        })}
                    </ol>
                </nav>
            </div>
        </div>
    )
}


export default Breadcrumb