import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import msalInstance from './authConfig';
import { MsalProvider } from '@azure/msal-react';
import store from './redux/store';
import { Provider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';


import App from './App';
import { ToastContainer } from 'react-toastify';


const root = ReactDOM.createRoot(document.getElementById('root'));
// console.log("msalInstance Header jsx ::",msalInstance);
root.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <BrowserRouter>
                <ToastContainer />
                <App />
            </BrowserRouter>
        </Provider>
    </MsalProvider>
);