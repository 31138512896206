import React, { useRef, useState } from 'react';
import './model.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Wrong from '../assets/images/wentWrong.png'

const style = {
    submitButton: { color: "#74AA6A", border: "none", borderRadius: "5px", backgroundColor: "#f5c40b", color: "#fff", boxShadow: "none" }
}

const SomethingWrong = ({isOpen}) => {
     
    const [open, setOpen] = useState(isOpen);

    const myRef = useRef(null);
    const handleSubmit = () => {
        setOpen(false);
        window.location.reload();
    }
    return (
        <>
            <div ref={myRef} />

            <Modal
                open={open}
                onClose={handleSubmit}
                center
                container={myRef.current}
            >
                <div style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <img src={Wrong} width={50} height={50} className='icon-model' />
                    <h6 style={{ fontSize: "14px", marginTop: "20px", color: "#f5c40b" }}>
                        Your session is timout, please login again
                    </h6>
                    {/* <p style={{ fontSize: "12px", color: "#000000" }}>{message}</p> */}
                    <button onClick={handleSubmit} style={style.submitButton}>Login</button>
                </div>
            </Modal>
        </>
    )
}

export default SomethingWrong