import React, { useEffect } from "react";

import styles from "./TableFooter.module.css";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const TableFooter = ({ range, setCurrentPage, currentPage, slice }) => {

  useEffect(() => {
    if (slice.length < 1 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [slice, currentPage, setCurrentPage]);

  function prePage(e) {
    e.preventDefault();
    setCurrentPage(currentPage - 1);

  }

  function nextPage(e) {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
  }


  return (
    <div className={styles.tableFooter}>


      <IoIosArrowBack onClick={prePage} color="#999999" />
      
      {range.map((el) => (

         
          <button
            key={el}
            className={`${styles.button} ${currentPage === el ? styles.activeButton : styles.inactiveButton
              }`}
            onClick={() => setCurrentPage(el)}
          >
            {el}
          </button>
         
      ))}

      <IoIosArrowForward onClick={nextPage} color="#333333" />

    </div>
  );
};



export default TableFooter;