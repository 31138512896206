import { Link } from "react-router-dom";
import Breadcrumb from "../../component/Breadcrumb";
import { MdKeyboardArrowRight } from "react-icons/md";
import translation from '../../assets/images/translation.png'

export const Translation = () => {
    return (
        <div className="dashboard-ecommerce">
            <div className="container-fluid dashboard-content">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Breadcrumb />
                        <div className='widget'>
                            <div className='card-header'>
                                Translations
                            </div>
                            <hr className="hr" />
                            <div className="row">

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className='card' style={{ height: "175px" }}>
                                        <div className="card-body-category">
                                            <img src={translation} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Translation Request Form</h5>
                                            <div className='text-container'>
                                                <p className="card-text">Submit a translation request via the Translation Request Form.</p>
                                            </div>
                                            <Link to="/translations/requestForm" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></Link>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className='card' style={{ height: "175px" }}>
                                        <div className="card-body-category">
                                            <img src={translation} alt="user_card" width="50" height="50" />
                                            <h5 className="text-muted">Reports</h5>
                                            <div className='text-container'>
                                                <p className="card-text">Click here to be view all translation reports.</p>
                                            </div>
                                            <Link to="/translations/reports" className="card-link">View More<span><MdKeyboardArrowRight size={20} color='black' /></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}